
import * as React from "react" 
import Layout from "../components/layout"
import Seo from "../components/seo"
import { motion, useViewportScroll, useTransform, m } from "framer-motion";
import { graphql } from "gatsby"

const container = {
    hidden: { opacity: 0 },
    delay: 0,
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.35
      }
    }
  };


export default function ProjectPage ({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Seo title="David Krasniy | Design &amp; Software Development" />

      <motion.div className='flex flex-col justify-center items-start max-w-4xl border-gray-200 dark:border-gray-700 mx-auto pb-16'

        initial={{ opacity: 0, y: 2 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: .4, delay:0 }, }}

        viewport={{ once: true }}>

<motion.ul
          className="w-full"
          variants={container}
          initial="hidden"
          animate="visible"
        >

          <div className="flex flex-col-reverse sm:flex-row items-start">
            <div className="flex flex-col w-full md:pr-8 ">

              <h1 className="font-bold text-3xl md:text-4xl tracking-tight mb-1 text-black dark:text-white text-center sm:text-left">{frontmatter.title}</h1>
              <h2 className="text-gray-700 dark:text-gray-200 mb-4 text-center sm:text-left">{frontmatter.date}</h2>
              <p className="text-gray-600 dark:text-gray-400 mb-8 sm:mb-16 text-center sm:text-left">Building delightful user experiences.</p>
            </div>
         
          </div>
 
        </motion.ul>

 
        </motion.div>
        </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`